import React from 'react'

const Plans = () => {
  return (
        <section className="bg-[#f1f1f1] py-16" id="pricing">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-bold text-center text-[#111111] mb-10">Our Pricing Plans</h2>
    
            {/* Pricing Cards */}
            <div className="flex flex-col md:flex-row justify-center items-center space-y-6 md:space-y-0 md:space-x-6">
              
              {/* Free Plan */}
              <div className="bg-white p-6 rounded-lg shadow-lg w-full md:w-1/3 text-center">
                <h3 className="text-2xl font-semibold text-[#111111]">Free Plan</h3>
                <p className="mt-4 text-lg text-gray-700">1000 API Hits</p>
                <p className="text-gray-500 mb-4">Get started with 1000 free API calls.</p>
                <p className="text-gray-700 mb-4">1000 Credits on Signup</p>
                <a href="#signup" className="inline-block bg-[#111111] text-white py-2 px-4 rounded-full hover:bg-gray-800">
                  Get Started Free
                </a>
              </div>
    
              {/* Starter Plan */}
              <div className="bg-white p-6 rounded-lg shadow-lg w-full md:w-1/3 text-center">
                <h3 className="text-2xl font-semibold text-[#111111]">Starter Plan</h3>
                <p className="mt-4 text-lg text-gray-700">10,000 API Hits</p>
                <p className="text-gray-500 mb-4">Best for growing projects.</p>
                <p className="text-gray-700 mb-4">Includes 5000 Credits</p>
                <a href="#signup" className="inline-block bg-[#111111] text-white py-2 px-4 rounded-full hover:bg-gray-800">
                  Start Now - $10/month
                </a>
              </div>
    
              {/* Pro Plan */}
              <div className="bg-white p-6 rounded-lg shadow-lg w-full md:w-1/3 text-center">
                <h3 className="text-2xl font-semibold text-[#111111]">Pro Plan</h3>
                <p className="mt-4 text-lg text-gray-700">Unlimited API Hits</p>
                <p className="text-gray-500 mb-4">Scale your project with unlimited access.</p>
                <p className="text-gray-700 mb-4">Includes 20,000 Credits</p>
                <a href="#signup" className="inline-block bg-[#111111] text-white py-2 px-4 rounded-full hover:bg-gray-800">
                  Upgrade - $25/month
                </a>
              </div>
            </div>
    
            {/* Newsletter CTA */}
            <div className="mt-10 text-center">
              <p className="text-lg text-[#111111]">Want the latest updates?</p>
              <a href="#newsletter" className="inline-block bg-blue-500 text-white py-2 px-6 rounded-full hover:bg-blue-600 mt-4">
                Subscribe to Newsletter
              </a>
            </div>
          </div>
        </section>
    
  )
}

export default Plans