import React from 'react';
import { FaGithub, FaTwitter, FaLinkedin } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-[#111111] text-[#f1f1f1] py-8">
      <div className="container mx-auto px-4">
        
        {/* Footer Links */}
        <div className="flex justify-center space-x-8 mb-6">
          <Link to="/home" className="hover:text-blue-400 transition-colors duration-200">Home</Link>
          <Link to="/price" className="hover:text-blue-400 transition-colors duration-200">Pricing</Link>
          <Link to="/about" className="hover:text-blue-400 transition-colors duration-200">About</Link>
          <Link to="/contact" className="hover:text-blue-400 transition-colors duration-200">Contact</Link>
        </div>

        {/* Social Media Icons */}
        <div className="flex justify-center space-x-6 mb-6">
          <a href="https://github.com/your-github" target="_blank" rel="noopener noreferrer" className="text-[#f1f1f1] hover:text-gray-400 text-2xl transition-colors duration-200">
            <FaGithub />
          </a>
          <a href="https://twitter.com/your-twitter" target="_blank" rel="noopener noreferrer" className="text-[#f1f1f1] hover:text-blue-400 text-2xl transition-colors duration-200">
            <FaTwitter />
          </a>
          <a href="https://linkedin.com/in/your-linkedin" target="_blank" rel="noopener noreferrer" className="text-[#f1f1f1] hover:text-blue-600 text-2xl transition-colors duration-200">
            <FaLinkedin />
          </a>
        </div>

        {/* Copyright */}
        <div className="text-center text-gray-400">
          <p>&copy; {new Date().getFullYear()} Mock Data API. All rights reserved.</p>
        </div>

      </div>
    </footer>
  );
};

export default Footer;
