import React from 'react'
import UserLayout from '../components/userLayout'
import Layout from '../components/layout'

const Support = () => {
  return (
   <UserLayout>
     <div>Profile</div>
   </UserLayout>
  )
}

export default Support