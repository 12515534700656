import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from '../pages/home'
import About from '../pages/about'
import Login from '../pages/login'
import Registration from '../pages/registration'
import NotFound from '../pages/notFound'
import Dashboard from '../pages/dashboard'
import Credentials from '../pages/credentials'
import Settings from '../pages/settings'
import Profile from '../pages/profile'
import Docs from '../pages/docs'
import Upgrade from '../pages/upgrade'
import Support from '../pages/support'
import Contact from '../pages/contact'
import Price from '../pages/price'
import Unauthorized from '../pages/unauthorised'
import ForgotPassword from '../pages/forgotPassword'
import ProtectedRoute from '../config/protectedRoute'
import Playground from '../pages/playground'
import VerifyEmail from '../pages/verifyEmail.js'

const RoutesPage = () => {
  return (
    <BrowserRouter>
        <Routes>
            <Route path='*' element={<NotFound/>}/>
            <Route path='/' element={<Home/>}/>
            <Route path='/about' element={<About/>}/>
            <Route path='/signin' element={<Login/>}/>
            <Route path='/signup' element={<Registration/>}/>
            {/* <Route path='/dashboard' element={<Dashboard/>}/> */}
            <Route path='/settings' element={<Settings/>}/>
            <Route path='/profile' element={<Profile/>}/>
            <Route path='/docs' element={<Docs/>}/>
            <Route path='/upgrade' element={<Upgrade/>}/>
            <Route path='/support' element={<Support/>}/>
            {/* <Route path='/credentials' element={<Credentials/>}/> */}
            <Route path='/contact' element={<Contact/>}/>
            <Route path='/price' element={<Price/>}/>
            <Route path='/unauthorized' element={<Unauthorized/>}/>
            <Route path='/forgot-password' element={<ForgotPassword/>}/>
            <Route path='/verifyemail' element={<VerifyEmail/>}/>

            <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />}/>
            <Route path="/credentials" element={<ProtectedRoute element={<Credentials />} />}/>
            <Route path="/Playground" element={<ProtectedRoute element={<Playground />} />}/>
        </Routes>
    </BrowserRouter>
  )
}

export default RoutesPage