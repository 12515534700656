import React from 'react'
import UserLayout from '../components/userLayout'

const Upgrade = () => {
  return (
  <UserLayout>
      <div>Upgrade</div>
  </UserLayout>
  )
}

export default Upgrade