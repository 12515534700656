import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";

const VerifyEmail = () => {
  const [isVerified, setIsVerified] = useState(false);
  const [resendEmail, setResendEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate(); // To navigate to the dashboard after verification

  // Poll the API to check if the email is verified
  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        const response = await axios.get("/api/check-email-verified"); // Replace with your API endpoint
        if (response.data.isVerified) {
          setIsVerified(true);
          clearInterval(interval); // Stop checking once verified
          navigate("/dashboard"); // Redirect to dashboard
        }
      } catch (error) {
        console.error("Error checking email verification status", error);
      }
    }, 5000); // Poll every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [navigate]);

  // Handle resending verification email
  const handleResendEmail = async () => {
    if (!resendEmail) {
      setError("Please enter your email.");
      return;
    }
    try {
      await axios.post("/api/resend-verification-email", {
        email: resendEmail,
      }); // Replace with your API endpoint
      setSuccess("Verification email resent successfully.");
      setError("");
      setOpenModal(false); // Close modal on success
    } catch (error) {
      setError("Failed to resend email. Please try again.");
    }
  };

  return (
    <div className="relative min-h-screen flex flex-col justify-center items-center bg-gray-100 text-center">
      <img src="/rings.svg" />

      {/* Verification Message */}
      <div className="">
        <h1 className="text-3xl font-bold mb-4">Verifing Your Email</h1>
        <p className="text-lg mb-6">
          A verification email has been sent to your email address. Please check
          your inbox and click on the link to verify your email.
        </p>

        <button
          onClick={() => setOpenModal(true)}
          className="bg-blue-500 text-white py-2 px-6 rounded-full hover:bg-blue-600 transition duration-300"
        >
          Resend Verification Email
        </button>

        <svg
          className="mx-auto mt-8 w-16 h-16 text-blue-500 animate-pulse"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M16 12l-4-4m0 0l-4 4m4-4v12"
          />
        </svg>
      </div>

      {/* Modal for Resending Email */}
      {openModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <h2 className="text-xl font-bold mb-4">
              Resend Verification Email
            </h2>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            {success && <p className="text-green-500 mb-4">{success}</p>}
            <input
              type="email"
              placeholder="Enter your email"
              value={resendEmail}
              onChange={(e) => setResendEmail(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
            />
            <div className="flex justify-between mt-4">
              <button
                onClick={handleResendEmail}
                className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300"
              >
                Send
              </button>
              <button
                onClick={() => setOpenModal(false)}
                className="bg-gray-300 text-black py-2 px-4 rounded-lg hover:bg-gray-400 transition duration-300"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VerifyEmail;