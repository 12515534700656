import React from "react";
import { Link, NavLink } from "react-router-dom";
import { FaHome, FaKey, FaCog, FaUser, FaArrowUp, FaLifeRing, FaQuestionCircle,FaCode } from "react-icons/fa";

const Sidebar = () => {
  return (
    <div className="h-screen bg-gray-100 text-gray-800 w-64 shadow-lg">
      <div className="p-6 text-2xl font-bold">
      <Link to='/'>
        <img src="/mock-logo-tranperent.png" alt="Mock Data API Logo" className="h-16" />
        </Link>
        </div>
      
      <ul className="space-y-6 p-4">
        <li>
          <NavLink 
            to="/dashboard" 
            className={({ isActive }) => isActive ? "block text-blue-600 bg-gray-300 rounded-lg p-2" : "block text-gray-800 hover:bg-gray-200 rounded-lg p-2"}
          >
            <FaHome className="inline mr-2" /> Dashboard
          </NavLink>
        </li>
        <li>
          <NavLink 
            to="/credentials" 
            className={({ isActive }) => isActive ? "block text-blue-600 bg-gray-300 rounded-lg p-2" : "block text-gray-800 hover:bg-gray-200 rounded-lg p-2"}
          >
            <FaKey className="inline mr-2" /> Credentials
          </NavLink>
        </li>
        <li>
          <NavLink 
            to="/settings" 
            className={({ isActive }) => isActive ? "block text-blue-600 bg-gray-300 rounded-lg p-2" : "block text-gray-800 hover:bg-gray-200 rounded-lg p-2"}
          >
            <FaCog className="inline mr-2" /> Settings
          </NavLink>
        </li>
        <li>
          <NavLink 
            to="/profile" 
            className={({ isActive }) => isActive ? "block text-blue-600 bg-gray-300 rounded-lg p-2" : "block text-gray-800 hover:bg-gray-200 rounded-lg p-2"}
          >
            <FaUser className="inline mr-2" /> Profile
          </NavLink>
        </li>
        <li>
          <NavLink 
            to="/upgrade" 
            className={({ isActive }) => isActive ? "block text-blue-600 bg-gray-300 rounded-lg p-2" : "block text-gray-800 hover:bg-gray-200 rounded-lg p-2"}
          >
            <FaArrowUp className="inline mr-2" /> Upgrade
          </NavLink>
        </li>
        <li>
          <NavLink 
            to="/playground" 
            className={({ isActive }) => isActive ? "block text-blue-600 bg-gray-300 rounded-lg p-2" : "block text-gray-800 hover:bg-gray-200 rounded-lg p-2"}
          >
            <FaCode className="inline mr-2" /> Playground
          </NavLink>
        </li>
        <li>
          <NavLink 
            to="/support" 
            className={({ isActive }) => isActive ? "block text-blue-600 bg-gray-300 rounded-lg p-2" : "block text-gray-800 hover:bg-gray-200 rounded-lg p-2"}
          >
            <FaLifeRing className="inline mr-2" /> Support
          </NavLink>
        </li>
        {/* <li>
          <NavLink 
            to="/help" 
            className={({ isActive }) => isActive ? "block text-blue-600 bg-gray-300 rounded-lg p-2" : "block text-gray-800 hover:bg-gray-200 rounded-lg p-2"}
          >
            <FaQuestionCircle className="inline mr-2" /> Help & Docs
          </NavLink>
        </li> */}
      </ul>
    </div>
  );
};

export default Sidebar;
