import React from "react";
import { FaGift, FaCogs, FaRocket, FaDatabase, FaUsers } from "react-icons/fa"; // Icons for sections
import { Link } from "react-router-dom";
import Layout from "../components/layout";
import Plans from "../components/plans";

const Home = () => {
  return (
    <Layout>
    <div className="bg-[#f1f1f1] text-gray-900">
      {/* Hero Section */}
      <section className="bg-gradient-to-r from-blue-500 to-purple-600 text-white py-20">
        <div className="container mx-auto text-center animate-fade-in">
          <h1 className="text-6xl font-bold mb-4">Mock Data API for Developers</h1>
          <p className="text-xl mb-6">
            Instantly generate mock data for products, orders, users, blogs, and more!
          </p>
          <Link
            to="/signup"
            className="bg-white text-blue-500 py-3 px-6 rounded-full font-bold text-lg shadow-lg hover:bg-gray-200 transition duration-300"
          >
            Get Started for Free
          </Link>
        </div>
      </section>

      {/* Feature Highlights */}
      <section className="py-20">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-8">What You Get When You Sign Up</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Feature 1 */}
            <div className="p-8 bg-white shadow-lg rounded-lg text-center hover:shadow-2xl transition duration-300">
              <FaGift className="text-6xl text-[#111] mx-auto mb-4 animate-bounce" />
              <h3 className="text-2xl font-bold mb-4">100 Credits Free</h3>
              <p>Sign up and get 100 free credits to explore our APIs and integrate them into your projects.</p>
            </div>

            {/* Feature 2 */}
            <div className="p-8 bg-white shadow-lg rounded-lg text-center hover:shadow-2xl transition duration-300">
              <FaRocket className="text-6xl text-[#111] mx-auto mb-4 animate-bounce" />
              <h3 className="text-2xl font-bold mb-4">Instant Data Generation</h3>
              <p>Generate mock data for your development projects with a single click.</p>
            </div>

            {/* Feature 3 */}
            <div className="p-8 bg-white shadow-lg rounded-lg text-center hover:shadow-2xl transition duration-300">
              <FaDatabase className="text-6xl text-[#111] mx-auto mb-4 animate-bounce" />
              <h3 className="text-2xl font-bold mb-4">Multiple APIs</h3>
              <p>Get access to APIs for products, users, orders, articles, and more to test your applications.</p>
            </div>

            {/* Feature 4 */}
            <div className="p-8 bg-white shadow-lg rounded-lg text-center hover:shadow-2xl transition duration-300">
              <FaUsers className="text-6xl text-[#111] mx-auto mb-4 animate-bounce" />
              <h3 className="text-2xl font-bold mb-4">User Management</h3>
              <p>Create, update, and delete mock users to simulate real-world applications.</p>
            </div>

            {/* Feature 5 */}
            <div className="p-8 bg-white shadow-lg rounded-lg text-center hover:shadow-2xl transition duration-300">
              <FaCogs className="text-6xl text-[#111] mx-auto mb-4 animate-bounce" />
              <h3 className="text-2xl font-bold mb-4">Developer Tools</h3>
              <p>Access various tools designed to help you test API integrations seamlessly.</p>
            </div>

            {/* Feature 6 */}
            <div className="p-8 bg-white shadow-lg rounded-lg text-center hover:shadow-2xl transition duration-300">
              <FaGift className="text-6xl text-[#111] mx-auto mb-4 animate-bounce" />
              <h3 className="text-2xl font-bold mb-4">Upgrade Options</h3>
              <p>Unlock advanced features by upgrading to higher plans with more credits.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="bg-gray-100 py-20">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-8">Why Choose Us?</h2>
          <p className="text-xl mb-8">
            Our Mock Data API is designed to help developers test and build applications faster with ready-to-use mock data.
          </p>
          <div className="flex flex-col md:flex-row justify-center items-center gap-6">
            <div className="p-8 bg-white rounded-lg shadow-lg text-center">
              <h3 className="text-2xl font-bold mb-2">Free Credits</h3>
              <p>Get 100 free credits to start testing immediately.</p>
            </div>
            <div className="p-8 bg-white rounded-lg shadow-lg text-center">
              <h3 className="text-2xl font-bold mb-2">Instant API Access</h3>
              <p>Get started in seconds with instant access to mock APIs for any project.</p>
            </div>
            <div className="p-8 bg-white rounded-lg shadow-lg text-center">
              <h3 className="text-2xl font-bold mb-2">Seamless Integration</h3>
              <p>Integrate our APIs easily with your existing applications and workflows.</p>
            </div>
          </div>
        </div>
      </section>


      {/* Final Call to Action */}
      <section className="bg-blue-500 text-white py-20">
        <div className="container mx-auto text-center">
          <h2 className="text-5xl font-bold mb-4">Get Started Today</h2>
          <p className="text-xl mb-8">Join thousands of developers using our Mock Data API to build, test, and scale faster.</p>
          <Link
            to="/signup"
            className="bg-white text-blue-500 py-3 px-8 rounded-full font-bold shadow-lg hover:bg-gray-200 transition duration-300"
          >
            Sign Up and Get 100 Free Credits
          </Link>
        </div>
      </section>
    </div>
    </Layout>
  );
};

export default Home;
