// import React from "react";
// import { FaCode, FaNetworkWired, FaExchangeAlt ,FaUsers } from "react-icons/fa"; // Icons for sections
// import Layout from "../components/layout";
// import { Link } from "react-router-dom";

// const Docs = () => {
//   return (
// <Layout>

// <div className="bg-gray-100 text-gray-900">
//       {/* Header Section */}
//       <section className="bg-blue-500 text-white py-16 text-center">
//         <div className="container mx-auto">
//           <h1 className="text-5xl font-bold mb-4">Mock Data API Documentation</h1>
//           <p className="text-lg mb-8">Easily generate mock data for your applications using our API. Below are the available endpoints with usage examples.</p>
//         </div>
//       </section>

//       {/* API Documentation Section */}
//       <section className="py-16">
//         <div className="container mx-auto max-w-4xl">
//           <h2 className="text-3xl font-bold mb-8">Available API Endpoints</h2>

//           {/* Example API Endpoint: Generate Product Data */}
//           <div className="bg-white p-6 rounded-lg shadow-lg mb-12">
//             <h3 className="text-2xl font-bold mb-4 flex items-center">
//               <FaNetworkWired className="text-blue-500 mr-2" /> Generate Product Data
//             </h3>
//             <p className="mb-6">This API generates mock product data to help developers simulate real-world data for their applications.</p>

//             <div className="mb-6">
//               <h4 className="text-xl font-semibold mb-2">Endpoint:</h4>
//               <code className="block bg-gray-200 p-2 rounded">POST /api/products/generate</code>
//             </div>

//             {/* Request Example */}
//             <div className="mb-6">
//               <h4 className="text-xl font-semibold mb-2">Request Example (cURL):</h4>
//               <pre className="bg-gray-800 text-white p-4 rounded-lg overflow-x-auto">
//                 <code>
//                   {`curl -X POST https://api.mockdata.com/products/generate \\
// -H "Content-Type: application/json" \\
// -d '{
//   "category": "Electronics",
//   "priceRange": {
//     "min": 10,
//     "max": 1000
//   },
//   "quantity": 50
// }'`}
//                 </code>
//               </pre>
//             </div>

//             {/* Request Payload */}
//             <div className="mb-6">
//               <h4 className="text-xl font-semibold mb-2">Request Payload:</h4>
//               <pre className="bg-gray-200 p-4 rounded-lg overflow-x-auto">
//                 <code>
//                   {`{
//   "category": "Electronics",  // Product category
//   "priceRange": {             // Price range
//     "min": 10,
//     "max": 1000
//   },
//   "quantity": 50              // Number of products to generate
// }`}
//                 </code>
//               </pre>
//             </div>

//             {/* Response Example */}
//             <div className="mb-6">
//               <h4 className="text-xl font-semibold mb-2">Response Example (Success):</h4>
//               <pre className="bg-gray-800 text-white p-4 rounded-lg overflow-x-auto">
//                 <code>
//                   {`{
//   "status": "success",
//   "data": [
//     {
//       "id": "1",
//       "name": "Smartphone XYZ",
//       "category": "Electronics",
//       "price": 499,
//       "stock": 100
//     },
//     {
//       "id": "2",
//       "name": "Laptop ABC",
//       "category": "Electronics",
//       "price": 999,
//       "stock": 50
//     }
//   ]
// }`}
//                 </code>
//               </pre>
//             </div>

//             {/* Error Response Example */}
//             <div className="mb-6">
//               <h4 className="text-xl font-semibold mb-2">Response Example (Error):</h4>
//               <pre className="bg-gray-800 text-white p-4 rounded-lg overflow-x-auto">
//                 <code>
//                   {`{
//   "status": "error",
//   "message": "Invalid category specified."
// }`}
//                 </code>
//               </pre>
//             </div>
//           </div>

//           {/* Another API Endpoint Example: Generate User Data */}
//           <div className="bg-white p-6 rounded-lg shadow-lg mb-12">
//             <h3 className="text-2xl font-bold mb-4 flex items-center">
//               <FaUsers className="text-blue-500 mr-2" /> Generate User Data
//             </h3>
//             <p className="mb-6">This API generates mock user data for developers to simulate user profiles in their applications.</p>

//             <div className="mb-6">
//               <h4 className="text-xl font-semibold mb-2">Endpoint:</h4>
//               <code className="block bg-gray-200 p-2 rounded">POST /api/users/generate</code>
//             </div>

//             <div className="mb-6">
//               <h4 className="text-xl font-semibold mb-2">Request Example (cURL):</h4>
//               <pre className="bg-gray-800 text-white p-4 rounded-lg overflow-x-auto">
//                 <code>
//                   {`curl -X POST https://api.mockdata.com/users/generate \\
// -H "Content-Type: application/json" \\
// -d '{
//   "gender": "female",
//   "ageRange": {
//     "min": 20,
//     "max": 35
//   },
//   "quantity": 20
// }'`}
//                 </code>
//               </pre>
//             </div>

//             <div className="mb-6">
//               <h4 className="text-xl font-semibold mb-2">Request Payload:</h4>
//               <pre className="bg-gray-200 p-4 rounded-lg overflow-x-auto">
//                 <code>
//                   {`{
//   "gender": "female",        // Gender of users
//   "ageRange": {              // Age range
//     "min": 20,
//     "max": 35
//   },
//   "quantity": 20             // Number of users to generate
// }`}
//                 </code>
//               </pre>
//             </div>

//             <div className="mb-6">
//               <h4 className="text-xl font-semibold mb-2">Response Example (Success):</h4>
//               <pre className="bg-gray-800 text-white p-4 rounded-lg overflow-x-auto">
//                 <code>
//                   {`{
//   "status": "success",
//   "data": [
//     {
//       "id": "1",
//       "name": "Jane Doe",
//       "age": 25,
//       "gender": "female",
//       "email": "jane.doe@example.com"
//     },
//     {
//       "id": "2",
//       "name": "Mary Smith",
//       "age": 30,
//       "gender": "female",
//       "email": "mary.smith@example.com"
//     }
//   ]
// }`}
//                 </code>
//               </pre>
//             </div>

//             <div className="mb-6">
//               <h4 className="text-xl font-semibold mb-2">Response Example (Error):</h4>
//               <pre className="bg-gray-800 text-white p-4 rounded-lg overflow-x-auto">
//                 <code>
//                   {`{
//   "status": "error",
//   "message": "Invalid gender specified."
// }`}
//                 </code>
//               </pre>
//             </div>
//           </div>

//         </div>
//       </section>

//       {/* Final Call to Action */}
//       <section className="bg-blue-500 text-white py-12 text-center">
//         <h2 className="text-4xl font-bold mb-4">Ready to Start Using Our API?</h2>
//         <p className="text-lg mb-8">Sign up today and get free access to our Mock Data API with 100 credits.</p>
//         <Link to="/signup" className="bg-white text-blue-500 py-3 px-6 rounded-full font-bold text-lg hover:bg-gray-200 transition duration-300">
//           Get Started for Free
//         </Link>
//       </section>
//     </div>
// </Layout>
//   );
// };

// export default Docs;

import React, { useState } from "react";
import { FaNetworkWired, FaUsers, FaFileAlt, FaPenFancy } from "react-icons/fa"; // Icons for categories
import Layout from "../components/layout";
import { Link } from "react-router-dom";

// Mock Data for API categories
const API_CATEGORIES = [
  { name: "Products", icon: <FaNetworkWired />, component: "products" },
  { name: "Users", icon: <FaUsers />, component: "users" },
  { name: "Articles", icon: <FaFileAlt />, component: "articles" },
  { name: "Posts", icon: <FaPenFancy />, component: "posts" },
];

const Docs = () => {
  const [selectedCategory, setSelectedCategory] = useState("products");

  // API Docs Content based on the selected tab
  const renderApiDocs = () => {
    switch (selectedCategory) {
      case "products":
        return (
          <div className="bg-white p-6 rounded-lg shadow-lg mb-12">
            <h3 className="text-2xl font-bold mb-4 flex items-center">
              <FaNetworkWired className="text-blue-500 mr-2" /> Generate Product Data
            </h3>
            <p className="mb-6">
              This API generates mock product data to help developers simulate
              real-world data for their applications.
            </p>
            {/* Endpoint, cURL Example, and Responses */}
            <div className="mb-6">
              <h4 className="text-xl font-semibold mb-2">Endpoint:</h4>
              <code className="block bg-gray-200 p-2 rounded">POST /api/products/generate</code>
            </div>
            <div className="mb-6">
              <h4 className="text-xl font-semibold mb-2">Request Example (cURL):</h4>
              <pre className="bg-gray-800 text-white p-4 rounded-lg overflow-x-auto">
                <code>{`curl -X POST https://api.mockdata.com/products/generate \\
-H "Content-Type: application/json" \\
-d '{
  "category": "Electronics",
  "priceRange": {
    "min": 10,
    "max": 1000
  },
  "quantity": 50
}'`}</code>
              </pre>
            </div>
            <div className="mb-6">
              <h4 className="text-xl font-semibold mb-2">Response Example (Success):</h4>
              <pre className="bg-gray-800 text-white p-4 rounded-lg overflow-x-auto">
                <code>{`{
  "status": "success",
  "data": [
    { "id": "1", "name": "Smartphone XYZ", "category": "Electronics", "price": 499 },
    { "id": "2", "name": "Laptop ABC", "category": "Electronics", "price": 999 }
  ]
}`}</code>
              </pre>
            </div>
          </div>
        );
      case "users":
        return (
          <div className="bg-white p-6 rounded-lg shadow-lg mb-12">
            <h3 className="text-2xl font-bold mb-4 flex items-center">
              <FaUsers className="text-blue-500 mr-2" /> Generate User Data
            </h3>
            <p className="mb-6">
              This API generates mock user data for developers to simulate user profiles in their applications.
            </p>
            {/* Endpoint, cURL Example, and Responses */}
            <div className="mb-6">
              <h4 className="text-xl font-semibold mb-2">Endpoint:</h4>
              <code className="block bg-gray-200 p-2 rounded">POST /api/users/generate</code>
            </div>
            <div className="mb-6">
              <h4 className="text-xl font-semibold mb-2">Request Example (cURL):</h4>
              <pre className="bg-gray-800 text-white p-4 rounded-lg overflow-x-auto">
                <code>{`curl -X POST https://api.mockdata.com/users/generate \\
-H "Content-Type: application/json" \\
-d '{
  "gender": "female",
  "ageRange": {
    "min": 20,
    "max": 35
  },
  "quantity": 20
}'`}</code>
              </pre>
            </div>
            <div className="mb-6">
              <h4 className="text-xl font-semibold mb-2">Response Example (Success):</h4>
              <pre className="bg-gray-800 text-white p-4 rounded-lg overflow-x-auto">
                <code>{`{
  "status": "success",
  "data": [
    { "id": "1", "name": "Jane Doe", "age": 25, "email": "jane@example.com" },
    { "id": "2", "name": "Mary Smith", "age": 30, "email": "mary@example.com" }
  ]
}`}</code>
              </pre>
            </div>
          </div>
        );
      case "articles":
        return (
          <div className="bg-white p-6 rounded-lg shadow-lg mb-12">
            <h3 className="text-2xl font-bold mb-4 flex items-center">
              <FaFileAlt className="text-blue-500 mr-2" /> Generate Article Data
            </h3>
            <p className="mb-6">This API generates mock article data to simulate blogs, news, and other types of articles.</p>
            {/* Endpoint, cURL Example, and Responses */}
            <div className="mb-6">
              <h4 className="text-xl font-semibold mb-2">Endpoint:</h4>
              <code className="block bg-gray-200 p-2 rounded">POST /api/articles/generate</code>
            </div>
            <div className="mb-6">
              <h4 className="text-xl font-semibold mb-2">Request Example (cURL):</h4>
              <pre className="bg-gray-800 text-white p-4 rounded-lg overflow-x-auto">
                <code>{`curl -X POST https://api.mockdata.com/articles/generate \\
-H "Content-Type: application/json" \\
-d '{
  "category": "Technology",
  "quantity": 10
}'`}</code>
              </pre>
            </div>
            <div className="mb-6">
              <h4 className="text-xl font-semibold mb-2">Response Example (Success):</h4>
              <pre className="bg-gray-800 text-white p-4 rounded-lg overflow-x-auto">
                <code>{`{
  "status": "success",
  "data": [
    { "id": "1", "title": "The Future of AI", "category": "Technology" },
    { "id": "2", "title": "Blockchain Innovations", "category": "Technology" }
  ]
}`}</code>
              </pre>
            </div>
          </div>
        );
      case "posts":
        return (
          <div className="bg-white p-6 rounded-lg shadow-lg mb-12">
            <h3 className="text-2xl font-bold mb-4 flex items-center">
              <FaPenFancy className="text-blue-500 mr-2" /> Generate Post Data
            </h3>
            <p className="mb-6">This API generates mock post data for social media, forums, or blogs.</p>
            {/* Endpoint, cURL Example, and Responses */}
            <div className="mb-6">
              <h4 className="text-xl font-semibold mb-2">Endpoint:</h4>
              <code className="block bg-gray-200 p-2 rounded">POST /api/posts/generate</code>
            </div>
            <div className="mb-6">
              <h4 className="text-xl font-semibold mb-2">Request Example (cURL):</h4>
              <pre className="bg-gray-800 text-white p-4 rounded-lg overflow-x-auto">
                <code>{`curl -X POST https://api.mockdata.com/posts/generate \\
-H "Content-Type: application/json" \\
-d '{
  "platform": "Twitter",
  "quantity": 15
}'`}</code>
              </pre>
            </div>
            <div className="mb-6">
              <h4 className="text-xl font-semibold mb-2">Response Example (Success):</h4>
              <pre className="bg-gray-800 text-white p-4 rounded-lg overflow-x-auto">
                <code>{`{
  "status": "success",
  "data": [
    { "id": "1", "platform": "Twitter", "content": "Exciting times!" },
    { "id": "2", "platform": "Twitter", "content": "API testing is fun!" }
  ]
}`}</code>
              </pre>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Layout>
      <div className="bg-gray-100 text-gray-900">
        {/* Header Section */}
        <section className="bg-blue-500 text-white py-16 text-center">
          <div className="container mx-auto">
            <h1 className="text-5xl font-bold mb-4">Mock Data API Documentation</h1>
            <p className="text-lg mb-8">
              Easily generate mock data for your applications using our API.
              Below are the available endpoints with usage examples.
            </p>
          </div>
        </section>

        {/* API Documentation Section */}
        <section className="py-16 flex">
          {/* Sidebar for Categories */}
          <div className="w-1/4 bg-gray-200 p-6">
            <h2 className="text-xl font-bold mb-6">API Categories</h2>
            <ul>
              {API_CATEGORIES.map((category) => (
                <li
                  key={category.name}
                  className={`cursor-pointer mb-4 p-3 rounded ${
                    selectedCategory === category.component
                      ? "bg-blue-500 text-white"
                      : "hover:bg-gray-300"
                  }`}
                  onClick={() => setSelectedCategory(category.component)}
                >
                  {category.icon} <span className="ml-2">{category.name}</span>
                </li>
              ))}
            </ul>
          </div>

          {/* Main Content for API Documentation */}
          <div className="w-3/4 p-6">
            {renderApiDocs()}
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Docs;
