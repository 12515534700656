import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FiMenu } from "react-icons/fi"; // Menu icon for mobile toggle
import { AiOutlineClose } from "react-icons/ai"; // Close icon for mobile toggle
import { useUser } from "../config/userProvider";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { userData } = useUser();

  console.log("userData header 11",userData);

  return (
    <header className="text-[#111111] py-4">
      <div className="container mx-auto flex justify-between items-center px-4">
        <div className="flex items-center space-x-4">
          <Link to="/">
            <img src="/logo-output.png" alt="Mock Data API Logo" className="h-10 md:h-16" />
          </Link>
        </div>

        {/* Mobile toggle button */}
        <button 
          className="md:hidden flex items-center" 
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? (
            <AiOutlineClose className="text-2xl" />
          ) : (
            <FiMenu className="text-2xl" />
          )}
        </button>

        {/* Navigation Links */}
        <nav className={`md:flex md:items-center md:space-x-4 ${isOpen ? "block" : "hidden"} absolute md:static top-16 left-0 w-full bg-white md:bg-transparent md:w-auto z-50`}>
          <ul className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 justify-center items-center md:justify-start p-4 md:p-0">
            <li>
              <Link to="/about" className="hover:text-blue-400">
                About
              </Link>
            </li>
            <li>
              <Link to="/price" className="hover:text-blue-400">
                Price
              </Link>
            </li>
            <li>
              <Link to="/docs" className="hover:text-blue-400">
                Docs
              </Link>
            </li>
            <li>
              <Link to="/contact" className="hover:text-blue-400">
                Contact
              </Link>
            </li>
            <li>
            {userData === null || userData === undefined?
              <Link to="/signin" className="inline-block border hover:border-[#111111] hover:text-[#111111] py-2 px-6 rounded-full text-[#f1f1f1] hover:bg-[#f1f1f1] bg-[#111111] transition duration-200">
                Sign In
              </Link>:
              <Link to="/dashboard" className="inline-block border hover:border-[#111111] hover:text-[#111111] py-2 px-6 rounded-full text-[#f1f1f1] hover:bg-[#f1f1f1] bg-[#111111] transition duration-200">
              Dashboard
              </Link>}
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;

