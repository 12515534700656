import React, { useState, useEffect } from "react";
import UserLayout from "../components/userLayout";
import { FaEye, FaEyeSlash, FaCopy, FaSyncAlt } from "react-icons/fa";
import axios from "axios";
import { useUser } from "../config/userProvider";

const Credentials = () => {
    const {userData} = useUser();

    console.log("userData",userData);

  const [apiData, setApiData] = useState({
    baseUrl: "",
    version: "",
    apiToken: userData?.apiToken,
  });
  const [showToken, setShowToken] = useState(false);
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);

  // Fetch data from API when component mounts
//   useEffect(() => {
//     fetchApiData();
//   }, []);

//   const fetchApiData = async () => {
//     try {
//       setLoading(true);
//       // Simulating API fetch
//       const response = await axios.get("/api/credentials"); // Replace with actual API call
//       setApiData(response.data);
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching API data", error);
//       setLoading(false);
//     }
//   };

  // Copy token to clipboard
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
  };

  // Handle generating new token
  const generateNewToken = async () => {
    const confirm = window.confirm(
      "This action cannot be undone. You will not be able to revert. Do you want to continue?"
    );
    if (confirm) {
      try {
        // Simulating API call for new token generation
        await axios.post("/api/generate-token"); // Replace with actual API endpoint
        alert("New token generated successfully!");
        // fetchApiData(); // Fetch new data after token is generated
      } catch (error) {
        console.error("Error generating new token", error);
      }
    }
  };

  return (
    <UserLayout>
      <div className="p-8">
        <h1 className="text-2xl font-bold mb-6">API Credentials</h1>

        {/* Show loading spinner or content */}
        {loading ? (
          <div>Loading...</div>
        ) : (
          <div className="bg-white p-6 rounded-lg shadow-lg space-y-6">
            {/* API Base URL */}
            <div>
              <h2 className="text-lg font-semibold">API Base URL:</h2>
              <p>https://api.shubhamsharma.xyz</p>
            </div>

            {/* API Version */}
            <div>
              <h2 className="text-lg font-semibold">API Version:</h2>
              <p>{apiData.version}</p>
            </div>

            {/* API Token */}
            <div>
              <h2 className="text-lg font-semibold flex items-center">API Token:</h2>
              <div className="flex items-center space-x-2">
                {/* API Token Display */}
                <input
                  type={showToken ? "text" : "password"}
                  value={apiData?.apiToken}
                  readOnly
                  className="w-full p-2 border rounded-md"
                />
                {/* Show/Hide Token Button */}
                <button
                  onClick={() => setShowToken(!showToken)}
                  className="p-2 bg-gray-100 rounded-md"
                >
                  {showToken ? <FaEyeSlash /> : <FaEye />}
                </button>
                {/* Copy to Clipboard Button */}
                <button
                  onClick={() => copyToClipboard(apiData.apiToken)}
                  className="p-2 bg-gray-100 rounded-md"
                >
                  <FaCopy />
                </button>
              </div>
              {copied && <p className="text-green-500 mt-2">Copied to clipboard!</p>}
            </div>

            {/* Generate New Token Button */}
            <div className="pt-4">
              <button
                onClick={generateNewToken}
                className="flex items-center px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition"
              >
                <FaSyncAlt className="mr-2" />
                Generate New Token
              </button>
            </div>
          </div>
        )}
      </div>
    </UserLayout>
  );
};

export default Credentials;

