import React from "react";
import UserLayout from "../components/userLayout";
import { useUser } from "../config/userProvider";

const Dashboard = () => {
const {userData} = useUser();
console.log("userData",userData);

  return (
    <UserLayout>
    <div className="p-8 bg-gray-100 w-full">
      <h1 className="text-3xl font-bold mb-6">Dashboard Overview</h1>

      {/* API Credits and Hit Info */}
      <div className="grid grid-cols-2 gap-6 mb-8">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-xl font-bold mb-2">Total Credits</h2>
          <p className="text-gray-600 text-4xl">{userData?.apiHitLimit}</p>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-xl font-bold mb-2">Total API Hit Limit</h2>
          <p className="text-gray-600 text-4xl">{userData?.currentHits}</p>
        </div>
      </div>

      {/* API Hit Breakdown by API Type */}
      <div className="bg-white p-6 rounded-lg shadow-lg mb-8">
        <h2 className="text-xl font-bold mb-4">API Hit Breakdown</h2>
        <ul>
            <li className="flex justify-between text-lg mb-2">
              {/* <span>{userData?.apiHits}:</span> */}
            </li>
        </ul>
      </div>


      {/* Current API Hits */}
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-xl font-bold mb-2">Current Hits Used</h2>
        <p className="text-gray-600 text-4xl">{userData?.currentHits}</p>
      </div>
    </div>
    </UserLayout>
  );
};

export default Dashboard;