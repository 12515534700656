import React from 'react';
import Layout from '../components/layout';
// import Logo from '../assets/mock-logo.svg';
// import Signature from '../assets/signature.svg';  // Add your signature image
import { FaGithub, FaTwitter, FaLinkedin } from 'react-icons/fa'; 

const About = () => {
  return (
    <Layout>
    <section className="bg-[#f1f1f1] min-h-screen py-16" id="about">
      <div className="container mx-auto px-4 text-center">
        {/* Big Logo */}
        <div className="mb-10 animate-fadeIn">
          <img src='/mock-logo-tranperent.png' alt="Mock Data API Logo" className="mx-auto h-40" />
        </div>

        {/* About the Creator */}
        <div className="max-w-2xl mx-auto">
          <h2 className="text-4xl font-bold text-[#111111] mb-4 animate-slideUp">
            About the Creator
          </h2>
          <p className="text-lg text-gray-700 mb-8 animate-slideUp delay-100">
            Hi! I'm John Doe, the developer behind Mock Data API. With a passion for open-source and API development, I built this platform to help developers access reliable, free mock data quickly and easily. I believe in the power of community-driven tools, and this project is designed to serve both beginner and experienced developers alike.
          </p>

          {/* Signature */}
          <div className="animate-slideUp delay-200">
            {/* <img src="/" alt="Creator's Signature" className="mx-auto h-16" /> */}
          </div>

          <div className="mt-6 animate-slideUp delay-300">
            <h3 className="text-2xl font-semibold text-[#111111] mb-4">Connect with Me</h3>
            <div className="flex justify-center space-x-6">
              <a href="https://github.com/your-github" target="_blank" rel="noopener noreferrer" className="text-gray-700 hover:text-[#111111] text-3xl transition-colors duration-200">
                <FaGithub />
              </a>
              <a href="https://twitter.com/your-twitter" target="_blank" rel="noopener noreferrer" className="text-gray-700 hover:text-[#1DA1F2] text-3xl transition-colors duration-200">
                <FaTwitter />
              </a>
              <a href="https://linkedin.com/in/your-linkedin" target="_blank" rel="noopener noreferrer" className="text-gray-700 hover:text-[#0077b5] text-3xl transition-colors duration-200">
                <FaLinkedin />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    </Layout>
  );
};

export default About;