import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Layout from "../components/layout.js";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { apiurl } from "../config/index.js";
import { setCookie } from "../config/webStorage.js";
import Loader from "../components/loader.js";
import { ToastContainer, toast } from "react-toastify";
import { validateToken } from "../config/validateToken.js";

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form default submission

    // Frontend validation
    if (!formData.email || !formData.password) {
      setError("Email and password are required!");
      return;
    }

    setError("");
    setLoading(true);

    try {
      // Making API request for login
      const response = await axios({
        url: `${apiurl}/api/v1/user/signin`,
        method: "POST",
        data: { email: formData.email, password: formData.password },
        headers: {
          "Content-Type": "application/json",
        },
      });

      // Handle login response
      // console.log(response.data);
      if (response.data.success === true) {
        const loginToken = response?.data?.token;
        const expirationTime = 50 * 60;
        setCookie("mlt", loginToken, expirationTime);

        validateToken(loginToken)
        .then((user) => {
          console.log("user login ",user)
            if (user?.user?.identity.email === false) {
              navigate("/verifyemail");
            } else {
              navigate("/dashboard");
            }
        })
        .catch((error) => {
          console.error("Token validation failed:", error);
        })
        .finally(() => {
          setLoading(false);
        });
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
        console.log("Error: " + response.data);
      }
    } catch (error) {
      console.error("Error during login:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };


  return (
    <Layout>
      {loading === true ? <Loader /> : " "}
      <ToastContainer />
      <div className="bg-[#f1f1f1] min-h-screen flex items-center justify-center px-4 md:px-0">
        <div className="w-full max-w-md bg-white shadow-lg rounded-lg p-8">
          <h2 className="text-3xl font-bold text-[#111111] mb-6 text-center">
            Sign in
          </h2>

          {error && <p className="text-red-500 text-center mb-4">{error}</p>}

          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-[#111111] mb-2" htmlFor="email">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>

            <label className="block text-[#111111] mb-2" htmlFor="password">
              Password
            </label>
            <div className="mb-6 relative">
              {/* Password Input Field */}
              <input
                type={showPassword ? "text" : "password"} // Toggle between text and password
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              />

              {/* Toggle Password Visibility */}
              <div
                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                onClick={() => setShowPassword(!showPassword)} // Toggle visibility on click
              >
                {showPassword ? (
                  <FaEyeSlash className="text-gray-500 hover:text-gray-700" />
                ) : (
                  <FaEye className="text-gray-500 hover:text-gray-700" />
                )}
              </div>
            </div>

            <button
              type="submit"
              className="w-full bg-[#111111] text-white py-2 rounded-lg hover:bg-gray-800"
            >
              Sign In
            </button>
          </form>

          {/* Forgot Password Link */}
          <div className="mt-4 text-center">
            <Link
              to="/forgot-password"
              className="text-blue-500 hover:underline"
            >
              Forgot Password?
            </Link>
          </div>

          {/* Create Account Link */}
          <div className="mt-6 text-center">
            <p className="text-gray-500">Don't have an account?</p>
            <Link
              to="/signup"
              className="text-blue-500 hover:underline font-bold"
            >
              Create an account
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Login;