import React from 'react'
import UserLayout from '../components/userLayout'

const Profile = () => {
  return (
   <UserLayout>
     <div>Profile</div>
   </UserLayout>
  )
}

export default Profile