import React from 'react'
import Layout from '../components/layout'

const ForgetPassword = () => {
  return (
  <Layout>
      <div>ForgetPassword</div>
  </Layout>
  )
}

export default ForgetPassword
