import React, { useState } from "react";
import Header from "./header";
import Footer from "./footer";
import Sidebar from "./sidebar";
import { Link, useNavigate } from "react-router-dom";
import {
  FaHome,
  FaKey,
  FaCog,
  FaUser,
  FaArrowUp,
  FaLifeRing,
  FaQuestionCircle,
} from "react-icons/fa";
import { useUser } from "../config/userProvider";
import { removeCookie } from "../config/webStorage";

const UserLayout = ({ children }) => {
  const { userData } = useUser();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    setLoading(true);
    // setOpenModal(false)
    removeCookie("mlt");
    window.location.href = "/";
  };

  return (
    <div className="flex min-h-screen">
      <Sidebar />
      <div className="flex-1">
        <header className="text-[#111111] py-4 border-b">
          <div className="container mx-auto flex justify-between items-center px-4">
            {/* User Greeting and Current Time */}
            <div className="flex items-center space-x-4">
              <div>
                <h2 className="text-lg font-semibold">
                  Hi {userData?.firstName} {userData?.firstName},
                </h2>
                <p className="text-sm text-gray-500">Current Plan: Starter</p>
                {/* <p className="text-sm text-gray-500">Date & Time (IST): {currentTime}</p> */}
              </div>
            </div>

            {/* Navigation Links */}
            <nav>
              <ul className="flex space-x-4 justify-center items-center">
                <li>
                  <Link
                    to="/docs"
                    target="_blank"
                    className="hover:text-blue-400"
                  >
                    Doc
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    target="_blank"
                    className="hover:text-blue-400"
                  >
                    Contact
                  </Link>
                </li>

                <li>
                  <button
                    onClick={() => setOpenModal(true)}
                    class="rounded-lg md:text-xl border border-[#111111] px-2  p-2 text-black "
                    type="submit"
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      version="1.1"
                      viewBox="0 0 17 17"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g></g>
                      <path d="M16 9.5c0 4.136-3.364 7.5-7.5 7.5s-7.5-3.364-7.5-7.5c0-3.498 2.476-6.579 5.888-7.326l0.214 0.977c-2.956 0.647-5.102 3.317-5.102 6.349 0 3.584 2.916 6.5 6.5 6.5s6.5-2.916 6.5-6.5c0-3.028-2.143-5.698-5.096-6.348l0.215-0.977c3.408 0.751 5.881 3.831 5.881 7.325zM9 0h-1v10h1v-10z"></path>
                    </svg>
                  </button>
                </li>
                {/* <li>
              <Link
                to="/upgrade"
                className="inline-block border hover:border-[#111111] hover:text-[#111111] py-2 px-6 rounded-full text-[#f1f1f1] hover:bg-[#f1f1f1] bg-[#111111] transition duration-200"
              >
                Upgrade Plan
              </Link>
            </li> */}
              </ul>
            </nav>
          </div>
        </header>
        <main className="p-4">{children}</main>
      </div>

      {openModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <h2 className="text-xl font-bold mb-4">Account Logout</h2>
            <div className="flex justify-between mt-4">
              {loading === true ? (
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
                  <div className="bg-white p-6 rounded shadow-lg text-center">
                    <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-gray-900"></div>
                  </div>
                </div>
              ) : null}
              <button
                onClick={handleLogout}
                className="bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300"
              >
                Logout
              </button>

              <button
                onClick={() => setOpenModal(false)}
                className="bg-gray-300 text-black py-2 px-4 rounded-lg hover:bg-gray-400 transition duration-300"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserLayout;
