import React from 'react'
import UserLayout from '../components/userLayout'

const Settings = () => {
  return (
   <UserLayout>
     <div>Settings</div>
   </UserLayout>
  )
}

export default Settings