import React, { useState } from 'react';
import UserLayout from '../components/userLayout';
import { FaCopy } from 'react-icons/fa';

const apis = {
  Products: ['GET /products/{id}', 'GET /products', 'POST /products', 'DELETE /products/{id}'],
  Orders: ['GET /orders/{id}', 'GET /orders', 'POST /orders', 'DELETE /orders/{id}'],
  Articles: ['GET /articles/{id}', 'GET /articles', 'POST /articles', 'DELETE /articles/{id}'],
  Checkout: ['GET /checkout/{id}', 'POST /checkout', 'DELETE /checkout/{id}'],
};

const versions = ['v1', 'v2', 'v3'];

const Playground = () => {
  const [selectedCategory, setSelectedCategory] = useState('Products');
  const [selectedApi, setSelectedApi] = useState('GET /products/{id}');
  const [selectedVersion, setSelectedVersion] = useState('v1');
  const [response, setResponse] = useState('');

  // Handler for sending request (for now it only simulates an API call)
  const handleSendRequest = async () => {
    // Simulating an API request
    const mockResponse = {
      data: `This is a mock response for ${selectedApi} in ${selectedVersion}`,
    };
    setResponse(JSON.stringify(mockResponse, null, 2));
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(response);
    alert('Response copied to clipboard');
  };

  return (
    <UserLayout>
      <div className="flex h-screen">
        {/* Sidebar for API categories */}
        <aside className="w-1/4 bg-gray-200 p-4">
          <h2 className="text-lg font-semibold mb-4">API Categories</h2>
          <ul>
            {Object.keys(apis).map((category) => (
              <li
                key={category}
                className={`cursor-pointer py-2 px-4 rounded ${
                  selectedCategory === category ? 'bg-blue-500 text-white' : 'hover:bg-gray-300'
                }`}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </li>
            ))}
          </ul>
        </aside>

        {/* Main content for API details */}
        <div className="w-3/4 p-6">
          <div className="mb-4">
            {/* API Version Selector */}
            <label htmlFor="version" className="mr-4 font-semibold">API Version:</label>
            <select
              id="version"
              value={selectedVersion}
              onChange={(e) => setSelectedVersion(e.target.value)}
              className="border rounded p-2"
            >
              {versions.map((version) => (
                <option key={version} value={version}>
                  {version}
                </option>
              ))}
            </select>
          </div>

          {/* API Methods based on selected category */}
          <div className="mb-4">
            <label className="block font-semibold mb-2">Select API Method:</label>
            <ul className="flex flex-wrap gap-4">
              {apis[selectedCategory].map((apiMethod) => (
                <li
                  key={apiMethod}
                  className={`cursor-pointer py-2 px-4 border rounded ${
                    selectedApi === apiMethod ? 'bg-blue-500 text-white' : 'hover:bg-gray-300'
                  }`}
                  onClick={() => setSelectedApi(apiMethod)}
                >
                  {apiMethod}
                </li>
              ))}
            </ul>
          </div>

          {/* Read-only input for the API URL */}
          <div className="mb-4">
            <label className="block font-semibold mb-2">API URL:</label>
            <input
              type="text"
              value={`http://localhost:5080/api/${selectedVersion}/${selectedApi.replace(' ', '/').replace('{id}', '1')}`}
              readOnly
              className="w-full p-2 border rounded"
            />
          </div>

          {/* Send Request Button */}
          <button
            onClick={handleSendRequest}
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
          >
            Send Request
          </button>

          {/* Response Section */}
          <div className="mt-6">
            <label className="block font-semibold mb-2">Response:</label>
            <div className="relative">
              <pre className="bg-gray-100 p-4 rounded h-48 overflow-auto">{response}</pre>
              <button
                onClick={copyToClipboard}
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              >
                <FaCopy />
              </button>
            </div>
          </div>
        </div>
      </div>
    </UserLayout>
  );
};

export default Playground;