import React from "react";
import { Link } from "react-router-dom";
import Layout from "../components/layout";

const Unauthorized = () => {
  return (
    <Layout>
    <div className="flex items-center justify-center h-screen bg-gray-100 text-gray-800">
      <div className="text-center p-6 bg-white rounded shadow-lg">
        <h1 className="text-4xl font-bold mb-4">Unauthorized Access</h1>
        <p className="mb-4">
          You do not have permission to view this page. Please check your
          credentials or contact support if you believe this is a mistake.
        </p>
        <Link 
          to="/" 
          className="inline-block mt-4 border border-gray-800 text-gray-800 py-2 px-4 rounded hover:bg-gray-800 hover:text-white transition duration-200"
        >
          Go to Home
        </Link>
      </div>
    </div>
    </Layout>
  );
};

export default Unauthorized;