import React, { useState } from "react";
import Select from "react-select"; // React-Select for searchable dropdown
import countryList from "react-select-country-list"; // Helper for country list
import Layout from "../components/layout.js";
import {
  FaLock,
  FaRegUser,
  FaMobileAlt,
  FaBuilding,
  FaGlobe,
  FaGift,
} from "react-icons/fa"; // Icons
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { apiurl } from "../config/index.js";
import { setCookie } from "../config/webStorage.js";
import Loader from "../components/loader.js";
import { ToastContainer, toast } from "react-toastify";

const Registration = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    organization: "",
    country: "",
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState("");
  const countries = countryList().getData();
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCountryChange = (selectedOption) => {
    setFormData({
      ...formData,
      country: selectedOption.value,
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const {
      firstName,
      lastName,
      email,
      phone,
      organization,
      country,
      password,
      confirmPassword,
    } = formData;

    // Basic form validation
    if (
      !firstName ||
      !lastName ||
      !email ||
      !phone ||
      !organization ||
      !country ||
      !password ||
      !confirmPassword
    ) {
      setError("All fields are required!");
      return;
    }

    // Check if passwords match
    if (password !== confirmPassword) {
      setError("Passwords do not match!");
      return;
    }

    setError("");
    // Add registration logic here

    try {
      // Making API request for login
      const response = await axios({
        url: `${apiurl}/api/v1/user/create`,
        method: "POST",
        data: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          phone: formData.phone,
          organization: formData.organization,
          country: formData.country,
          email: formData.email,
          password: formData.password,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data.success === true) {
        toast.success(response.data.message);
        navigate("/verifyemail");
      } else {
        toast.error(response.data.message);
        console.log("Error: " + response.data);
      }
    } catch (error) {
      console.error("Error during login:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
    console.log("Registration successful!", formData);
  };

  return (
    <Layout>
      {loading === true ? <Loader /> : " "}
      <ToastContainer />
      <div className="bg-[#f1f1f1] p-5 flex flex-col md:flex-row items-center justify-center">
        {/* Left Section: Registration Form */}
        <div className="w-full max-w-lg bg-white shadow-lg rounded-lg p-8 md:mr-8">
          <h2 className="text-3xl font-bold text-[#111111] mb-6 text-center">
            Sign Up
          </h2>

          {error && <p className="text-red-500 text-center mb-4">{error}</p>}

          <form onSubmit={handleSubmit}>
            {/* First and Last Name on the same line */}
            <div className="md:flex md:space-x-4 mb-4">
              <div className="md:w-1/2">
                <label
                  className="block text-[#111111] mb-2"
                  htmlFor="firstName"
                >
                  <FaRegUser className="inline mr-1" /> First Name
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                />
              </div>

              <div className="md:w-1/2 mt-4 md:mt-0">
                <label className="block text-[#111111] mb-2" htmlFor="lastName">
                  <FaRegUser className="inline mr-1" /> Last Name
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                />
              </div>
            </div>

            <div className="mb-4">
              <label className="block text-[#111111] mb-2" htmlFor="email">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>

            <div className="mb-4">
              <label className="block text-[#111111] mb-2" htmlFor="phone">
                <FaMobileAlt className="inline mr-1" /> Phone
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-[#111111] mb-2"
                htmlFor="organization"
              >
                <FaBuilding className="inline mr-1" /> Organization
              </label>
              <input
                type="text"
                id="organization"
                name="organization"
                value={formData.organization}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>

            <div className="mb-4">
              <label className="block text-[#111111] mb-2">
                <FaGlobe className="inline mr-1" /> Country/Region
              </label>
              {/* Country Select with Search */}
              <Select
                options={countries} // List of countries
                value={countries.find(
                  (option) => option.value === formData.country
                )} // Pre-select country if available
                onChange={handleCountryChange} // Update form state on country select
                className="w-full text-black"
                placeholder="Select a country"
              />
            </div>

            <div className="mb-4">
              <label className="block text-[#111111] mb-2" htmlFor="password">
                <FaLock className="inline mr-1" /> Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>

            <div className="mb-6">
              <label
                className="block text-[#111111] mb-2"
                htmlFor="confirmPassword"
              >
                <FaLock className="inline mr-1" /> Confirm Password
              </label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>

            <button
              type="submit"
              className="w-full bg-[#111111] text-white py-2 rounded-lg hover:bg-gray-800"
            >
              Sign Up
            </button>
          </form>
        </div>

        {/* Right Section: Promo Box */}
        <div className="w-full max-w-lg bg-[#111111] text-white shadow-lg rounded-lg p-8 mt-8 md:mt-0">
          <h2 className="text-2xl font-bold mb-4">
            Get 100 Credits Free on Signup
          </h2>
          <p className="mb-4">
            When you sign up, you'll get 100 credits for free to explore
            multiple APIs and start building your applications right away.
          </p>
          <ul className="list-disc list-inside space-y-2 mb-6">
            <li>Access to multiple APIs</li>
            <li>Free playground to test your API integration</li>
            <li>Upgrade options for more advanced features</li>
          </ul>
          <FaGift className="text-6xl mx-auto" />
        </div>
      </div>
    </Layout>
  );
};

export default Registration;
