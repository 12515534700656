import React from "react";
import Layout from "../components/layout";
import Plans from "../components/plans";
import { Link } from "react-router-dom";

const Price = () => {
  return (
    <Layout>
           <section className="bg-[#f1f1f1] text-gray-900 py-20">
        <div className="container mx-auto text-center">
          <h2 className="text-5xl font-bold mb-4">Free Mock Data API</h2>
          <p className="text-xl mb-8">
            Generate mock data for products, orders, users, and more instantly!
          </p>
          <Link
            to="/signup"
            className="bg-blue-500 text-white py-3 px-6 rounded-full hover:bg-blue-600"
          >
            Get Started
          </Link>
        </div>
      </section>
      <Plans/>
    </Layout>
  );
};

export default Price;
